import React from 'react'
import { Link } from 'gatsby'

import '../components/globalStyle.css'
import '../components/product.css'

import Header from '../components/header'
import BackButton from '../components/backButton'


const productLiquality = () => (
  <div className='productLiqualityGroup'>
    <Header />

    <BackButton />

    <div className="product">

      <div className="productText">

        <h2> Liquality </h2>
        <p className="productInfo">
          <i>
            June 2017 - June 2018<br></br>
            Designed w/ Thessy Mehrain<br></br>
          </i>
        </p>

        <p>
          Liquality is a dis-intermediated cryptocurrency exchange protocol.
          <br></br>
          <br></br>
          Exchanges that claim to convert one cryptocurrency to another require a central service to execute the exchange manually. This opens up vulnerabilities because this service could be attacked and funds could be stolen. Or they could act against the interest of the users. With Liquality protocol, all you need is another person willing to trade with you, and you can exchange. The protocol makes it impossible for this other party to run away with your funds or change the terms of your agreement.
          <br></br>
          <br></br>
          The Liquality Platform allows you to connect with users who want to trade with you, and walks you through the protocol to let you do that safely and trustlessly.
          <br></br>
          <br></br>
          If you're interested in what they do, <a href="https://medium.com/liquality/10-years-later-enter-liquality-5ae449ab81d3"> <u>here is an article you can read</u></a>
          <br></br>
          <br></br>
          <b>Thessy and I built a platform that used the Liqualaity protocol to aggregate and match compatible requests to swap currencies.</b> During that time, we explored several iterations of the platform until we reached on that most closely aligned with the team's dis-intermediated vision.
        </p>

      </div>

      <figure className="productImg">
        <img src={require('../img/liquality/liqualityGenerative.svg')} />
        <figcaption>We started with generative research. We reached out to our key user groups to identify pain points in their currency exchange experience.</figcaption>
      </figure>

      <figure className="productImg">
        <img src={require('../img/liquality/liqualityDesignThinking.jpg')} />
        <figcaption>I met with the team to communicate learnings from the research. I facilitated several collaborative sessions where we mapped out features and priorities for a prototype.</figcaption>
      </figure>

      <figure className="productImg">
        <img src={require('../img/liquality/liqualityIterations.jpg')} />
        <figcaption>I designed the lo-fi and hi-fi wireframes went through several iterations. In order to gain feedback and insights as early as possible, I periodically tested prototypes with users. I also consistently opened the process up to the team to ensure we were still aligned in mission and vision. </figcaption>
      </figure>

      <figure className="productImg">
        <img src={require('../img/liquality/liqualityPrototype.gif')} />
        <figcaption>This is the last version of the prototype that I worked on. </figcaption>
      </figure>

      <div className="productText">
        <p>Crypto trades are very detailed and complicated. We found our users really valued control and security. Because of this, the process could not be a one-click, "easy" solution. Instead, this project challenged me to build something that was easy to understand and use without being reductive.</p>
      </div>
    </div>
  </div>
)
export default productLiquality
